import * as React from 'react'
import Layout from '../components/Layout'

// markup
const NotFoundPage = (pageContext) => {
  return (
    <Layout pageContext={pageContext}>
      <div className="container content-container">
        <h2>404: Page not found!</h2>
        <p>Die gesuchte Seite wurde nicht gefunden.</p>
        <p>La page recherchée n'a pas été trouvée.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
